<style scoped lang="less">
  .replay {
    color: #657180;
    word-break: break-all;
    padding-left: 20px;
    position: relative;
    &::before {
      z-index: 1;
      left: -9px;
      top: 12px;
      background-color: #FFF;
      font-family: 'iconfont';
      position: absolute;
      content: '\e609';
      display: block;
      border: 3px solid #FFF;
    }
    .replay-wrap {
      border: 1px solid #EEE;
      border-radius: 3px;
      padding: 10px;
      position: relative;
      &::before {
        transform: rotate(-45deg);
        position: absolute;
        top: 16px;
        content: '';
        left: -5px;
        display: block;
        width: 8px;
        height: 8px;
        border: 1px solid #EEE;
        border-right: none;
        border-bottom: none;
        background-color: #FFF;
        z-index: 1;
      }
    }
    .content {
      word-break: break-all;
      word-wrap: break-word;
      white-space: pre-wrap;
      margin: 0 0 10px 0;
    }
    .author {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
  }
</style>

<template>
  <div class="replay">
    <div class="replay-wrap">
      <pre class="content">{{data.content}}</pre>
      <div class="author">
        <div>{{data.userName}}</div>
        <div>{{data.createTime}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: null }
  }
}
</script>
