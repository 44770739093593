<style scoped>
  .task-filters {
    flex: 1;
    display: flex;
    align-items: center;
  }
</style>

<template>
  <div class="task-filters">
    <div style="position: relative;flex: 1 1 0%;display: flex;align-items: center;">
      <render-filters style="position: absolute;flex-wrap: wrap;justify-content: flex-end;width: 100%;" :filters="filters" :value="value" @change="onChange" />
    </div>
    <fm-down-select v-model="filters" :options="options" multiple>
      <div style="cursor: pointer;">+筛选</div>
    </fm-down-select>
  </div>
</template>

<script>
import RenderFilters from './render/filters'
import { taskFilters } from './lib'

export default {
  components: {
    RenderFilters
  },
  props: {
    value: {
      type: Object, default () {
        return {}
      }
    }
  },
  data () {
    return {
      filters: JSON.parse(localStorage.getItem('task-filters') || '[]')
    }
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        localStorage.setItem('task-filters', JSON.stringify(this.filters))
      }
    }
  },
  computed: {
    options () {
      return taskFilters
    }
  },
  methods: {
    onChange (field, data) {
      this.$emit('change', field, data)
    }
  }
}
</script>
