<style scoped>
  .task-source {
    height: 100%;
  }
</style>

<template>
  <div class="task-source">
    <component :is="component" ref="data" :id="dataId" :type="dataType" @source-switch="sourceSwitch" :query="query" @data-theme-change="dataThemeChange"></component>
  </div>
</template>

<script>
import Person from './source/person'
export default {
  props: {
    type: { type: String, default: null },
    id: { type: Number, default: null },
    query: { type: Object, default: null }
  },
  computed: {
    component () {
      // 根据dataType显示不同的组件
      if (this.dataType === 'person') {
        return Person
      }
      return Person
    },
    dataType () {
      return this.type ? this.type : this.$route.query.type
    },
    dataId () {
      let id = this.type ? this.type : this.$route.query.id
      return id ? Number(id) : id
    }
  },
  methods: {
    loadData () {
      this.$refs.data.loadData()
    },
    sourceSwitch (source) {
      this.$emit('source-switch', source)
    },
    dataThemeChange (theme) {
      this.$emit('data-theme-change', theme)
    }
  }
}
</script>
