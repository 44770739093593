<style scoped lang="less">
  .blackboard {
    flex: 1;
    display: flex;
    background-color: #FFF;
    &.is-edit {
      .wrap {
        cursor: pointer;
      }
    }
  }
  .toggle {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    z-index: 2;
    .fmico {
      font-size: 25px;
      color: #2F5BEA;
    }
  }
  .wrap {
    position: relative;
    flex: 1;
    z-index: 1;
  }
</style>

<template>
  <div class="blackboard" :class="{
    'is-edit': isEdit
  }">
    <div class="toggle" @click="isEdit = !isEdit">
      <i v-if="!isEdit" class="fmico fmico-edit"></i>
      <i v-else class="fmico fmico-eye"></i>
    </div>
    <div class="wrap" ref="wrap" @dblclick.self="insert">
      <item-board v-for="(item, i) in items"
        :key="i + '-' + item.id"
        :id="item.id"
        :x="item.x"
        :y="item.y"
        :width="item.width"
        :content="item.content"
        ref="items" />
    </div>
  </div>
</template>

<script>
import ItemBoard from './item'
import { blackboardRequest } from '@/api'

let demos = [{"id":"tmp1631022040920","x":3.0959752321981426,"y":7.142857142857139,"width":20.149638802889577,"content":"1. 内容为空时，继续按下删除键则会删除当前标签"},{"id":"tmp1631022118226","x":3.611971104231166,"y":35.714285714285715,"width":19.840041279669762,"content":"2. 按下Ctrl+删除则可以删除当前标签"},{"id":"tmp1631022145211","x":2.579979360165119,"y":64.28571428571428,"width":25,"content":"3. 鼠标悬浮时，右上角的红点可以关闭当前标签"},{"id":"tmp1631022172353","x":29.61816305469556,"y":9.523809523809524,"width":25,"content":"4. 按下Esc键可以退出标签的编辑模式<br>标签会自动保存"},{"id":"tmp1631022205452","x":29.618163054695557,"y":45.91836734693877,"width":25,"content":"5. 点击其他空白区域，使标签失去焦点也可触发自动保存"},{"id":"tmp1631022278330","x":61.19711042311662,"y":10.204081632653068,"width":25,"content":"7. 拖拽标签空白区域可以调整位置"},{"id":"tmp1631022289114","x":61.609907120743046,"y":28.91156462585034,"width":25,"content":"8. 鼠标放在标签尾部可以调整标签宽度<br><br>"},{"id":"tmp1631022301504","x":61.71310629514964,"y":62.58503401360544,"width":25,"content":"9. 不断地输入内容标签高度会自动撑开"},{"id":"tmp1631022547328","x":29.30856553147575,"y":73.20261437908496,"width":25,"content":"6. 自动识别是否有更新，无更新不会触发保存"}]

export default {
  components: { ItemBoard },
  data () {
    return {
      isEdit: false,
      startResize: false,
      items: []
    }
  },
  provide () {
    return {
      blackboard: this
    }
  },
  methods: {
    insert ({offsetX, offsetY}) {
      if (!this.startResize && this.isEdit) {
        this.items.push({
          id: 'tmp' + new Date().getTime(),
          x: (offsetX / this.$refs.wrap.clientWidth) * 100,
          y: (offsetY / this.$refs.wrap.clientHeight) * 100,
          width: ((this.$refs.wrap.clientWidth / 4) / this.$refs.wrap.clientWidth) * 100,
          content: ''
        })
        this.$nextTick(() => this.$refs.items[this.$refs.items.length - 1].$el.querySelector('div.input').focus())
      }
    },
    async onItemRemove (item) {
      let index = this.items.findIndex(v => v.id === item.id)
      if (index > -1) {
        try {
          item.loading.delete = true
          if (!isNaN(this.items[index].id)) {
            await blackboardRequest.del(this.items[index].id)
          }
          this.items.splice(index, 1)
        } catch (error) {
          console.error(error)
        }
        item.loading.delete = false
      }
    },
    async onItemUpdate (item) {
      let index = this.items.findIndex(v => v.id === item.id)
      if (index > -1) {
        let newData = {
          x: item.left,
          y: item.top,
          width: item.length,
          content: item.$refs.input.innerHTML.trim()
        }
        if (Object.keys(newData).some((key) => this.items[index][key] !== newData[key])) {
          try {
            item.loading.update = true
            if (isNaN(item.id)) {
              let res = await blackboardRequest.add(newData)
              newData.id = res.id
            } else {
              await blackboardRequest.update(item.id, newData)
            }
            Object.assign(this.items[index], newData)
          } catch (error) {
            console.error(error)
          }
          item.loading.update = false
        }
      }
    },
    merge (datas) {
      let ids = this.items.filter(v => v.id).map(v => v.id)
      for (let data of datas) {
        let item = ids.includes(data.id) ? this.items.find((v) => v.id === data.id) : null
        if (item && Object.keys(data).some((key) => item[key] !== data[key])) {
          Object.assign(item, data)
        } else {
          this.items.push(data)
        }
      }
    },
    async loadItems () {
      let data = await blackboardRequest.get()
      this.items = data.length ? data : demos
    }
  },
  mounted () {
    this.$on('remove', this.onItemRemove)
    this.$on('update', this.onItemUpdate)
    this.loadItems()
  }
}
</script>
