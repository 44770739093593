<style scoped lang="less">
  .item {
    position: absolute;
    display: flex;
    background-color: #FFF;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    transition: top .3s, left .3s, opacity .3s, box-shadow .3s;
    will-change: top, left, width;
  }
  .input {
    color: #707070;
    padding: 10px 7px;
    border-radius: 5px;
    word-break: break-all;
    word-wrap: break-word;
    flex: 1;
    font-size: 17px;
    cursor: text;
    &:focus {
      outline: none;
    }
    &:empty::before {
      color: #AAA;
      content: '请输入内容...';
    }
  }
  .resize {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    cursor: col-resize;
    z-index: 1;
    transition: background-color .3s;
    border-radius: 5px;
    &:hover {
      background-color: rgba(0, 0, 0, .1);
    }
  }
  @keyframes loading {
    0% {opacity: .1;}
    100% {opacity: .8;}
  }
  .is-delete, .is-update {
    pointer-events: none;
    cursor: not-allowed;
    animation: loading 1s linear infinite alternate;
    .remove, .resize {
      display: none;
    }
  }
  .is-delete {
    .input {
      &::after {
        margin-left: 5px;
        content: '删除中...';
        color: red;
      }
    }
  }
  .is-update {
    .input {
      &::after {
        margin-left: 5px;
        content: '保存中...';
        color: green;
      }
    }
  }
  .is-focus {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3), 0 0 3px 0 rgba(0, 0, 0, .3) inset;
    .remove {
      display: none !important;
    }
  }
  .remove {
    position: absolute;
    top: -8px;
    right: -8px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #3039EA;
    z-index: 2;
    opacity: 0;
    transition: opacity .3s;
    padding: 4px;
    &:hover {
      opacity: 1 !important;
    }
    &::before {
      color: #FFF;
      font-family: 'fmico';
      content: '\e61c';
    }
  }
  .item:hover {
    .remove {
      opacity: .3;
    }
  }
</style>

<template>
  <div class="item" :style="styles" :class="{
    'is-delete': loading.delete,
    'is-update': loading.update,
    'is-focus': status.focus
  }">
    <div class="input"
      :draggable="isEdit && !status.focus"
      @click="onClick"
      @mousedown="mousedown"
      @dragend.prevent="dragend"
      ref="input"
      @keydown.esc="esc"
      @keydown.delete="backspace"
      :style="{width: width + '%'}"
      tabindex
      @blur="blur"
      @focus="status.focus = true" v-html="text"></div>
    <div class="resize" v-if="isEdit" @mousedown.stop.prevent="resizeStart"></div>
    <div class="remove" v-if="isEdit" @click.stop="remove"></div>
  </div>
</template>

<script>
var timer = null
export default {
  props: {
    id: { type: [Number, String], default: null },
    x: { type: Number, default: 0 },
    y: { type: Number, default: 0 },
    width: { type: Number, default: 0 },
    content: { type: String, default: '' }
  },
  inject: ['blackboard'],
  data () {
    return {
      top: this.y,
      left: this.x,
      length: this.width,
      text: this.content,
      dragOffset: {x: 0, y: 0},
      widthOffset: 0,
      loading: {
        delete: false, update: false
      },
      status: {
        focus: false
      }
    }
  },
  computed: {
    isEdit () {
      return this.blackboard.isEdit
    },
    styles () {
      return {
        top: this.top + '%',
        left: this.left + '%',
        width: this.length + '%'
      }
    },
    isLoading () {
      return this.loading.delete || this.loading.update
    }
  },
  methods: {
    onClick () {
      if (this.isEdit) {
        this.$refs.input.setAttribute('contenteditable', true)
        this.$refs.input.focus()
      }
    },
    report (type) {
      this.blackboard.$emit(type, this)
    },
    remove () {
      this.report('remove')
    },
    blur () {
      if (!this.isLoading) {
        this.status.focus = false
        this.$refs.input.removeAttribute('contenteditable')
        if (this.$refs.input.innerHTML.trim() === '') {
          this.remove()
        } else {
          this.report('update')
        }
      }
    },
    backspace (e) {
      if (e.ctrlKey || this.$refs.input.innerHTML.trim() === '') {
        this.remove()
      }
    },
    esc () {
      this.$refs.input.blur()
    },
    mousedown ({x, y}) {
      this.dragOffset.x = x
      this.dragOffset.y = y
    },
    dragend ({x, y}) {
      this.top += (y - this.dragOffset.y) / this.blackboard.$refs.wrap.clientHeight * 100
      this.left += (x - this.dragOffset.x) / this.blackboard.$refs.wrap.clientWidth * 100
      this.report('update')
    },
    resizeMove ({x}) {
      this.length += (x - this.widthOffset) / this.blackboard.$refs.wrap.clientWidth * 100
      this.widthOffset = x
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.report('update')
      }, 500)
    },
    resizeStart ({x}) {
      this.blackboard.startResize = true
      this.widthOffset = x
      document.body.addEventListener('mousemove', this.resizeMove)
      let mouseup = () => {
        document.body.removeEventListener('mouseup', mouseup)
        document.body.removeEventListener('mousemove', this.resizeMove)
        this.widthOffset = 0
        mouseup = null
        setTimeout(() => {
          this.blackboard.startResize = false
        }, 300)
      }
      document.body.addEventListener('mouseup', mouseup)
    }
  }
}
</script>
