<style scoped lang="less">
  .task-details {
    display: flex;
    width: 700px;
    transition: all .3s;
    height: 100%;
    &.stretch {
      width: 90vw;
    }
    &.page {
      height: unset;
      width: unset;
    }
    &.page.stretch {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .info {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;
      .icon-fenxiang {
        color: #657180;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 27px;
        transition: all .3s;
        &:hover {color: #2F5BEA;}
      }
      .title {
        display: flex;
        position: relative;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        padding: 20px 40px 10px 40px;
        color: #707070;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
        .title-input {
          flex: 1;
        }
        &::before {
          // content: attr(data-id);
        }
        .files {
          max-height: 160px;
          overflow: auto;
        }
      }
      .editor {
        z-index: 0;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        overflow: auto;
        border: 1px solid #EEE;
        border-left: none;
        border-right: right;
      }
      .editor-action, .replay {
        padding: 10px 20px;
      }
      .files {
        
      }
      .replay-lists {
        padding: 0 10px;
      }
    }
    .actions {
      transition: all .3s;
      background-color: #FCFCFD;
      border-left: 1px solid #E8E8E8;
      width: 210px;
      display: flex;
      flex-direction: column;
      .header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-top: 10px;font-size: 20px;font-weight: 500;line-height: 24px;color: #000000;text-align: left;margin-bottom: 10px;
      }
      .content {
        overflow: auto;
        height: 0;
        flex: 1;
      }
    }
    &.stretch, &.page {
      .actions {
        width: 300px;
      }
    }
    .down-select {
      cursor: pointer;
    }
  }
  .points-title {
    font-size: 22px;font-weight: 500;position: relative;left: 15px;
    &:after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: -15px;
      height: 1px;
      width: 100%;
      background-color: #EEE;
    }
  }
  .menus {
    position: absolute;
    z-index: 1;
    left: -13px;
    top: 10%;
    display: flex;
    flex-direction: column;
    .iconfont {
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      color: #FFF;
      border-radius: 10px;
      background-color: #9FACC3;
      transition: all .3s;
      &.icon-zhankai1 {
        transform: rotate(180deg);
        &.stretch {
          transform: rotate(0);
        }
      }
      &:hover {
        background-color: #2F5BEA;
      }
    }
    & > * + * {
      margin-top: 10px;
    }
  }
</style>

<style lang="less">
  .task-details {
    .editormd {
      margin: 0;
      border: none;
      .CodeMirror {
        border: none;
      }
      &.editormd-fullscreen {
        z-index: 999;
      }
    }
    .title-input {
      input {
        font-size: 22px;
      }
    }
    .info {
      .files {
        .file-page {
          nav {
            ul {
              li:first-of-type {
                font-weight: 500;
                position: relative;
                font-size: 22px;
                left: -10px;
              }
            }
          }
        }
      }
    }
  }
</style>

<template>
  <div class="task-details" :class="{stretch: stretch, page: isPage}">
    <div class="info">
      <div ref="header">
        <div class="title" :data-id="'【' + (data.id || 'New') + '】'">
          <task-title class="title-input" :title="data.title" @change="(data) => onFieldChange('title', data)" style="margin-right: 20px;"></task-title>
          <fm-btn v-if="data.id" @click="del" v-loadingx="loading.del">删除</fm-btn>
        </div>

        <div v-if="hasEditor" class="editor-action">
          <fm-btn v-if="!openEditor" @click="resize(), openEditor = true" v-loadingx="loading.save">编辑</fm-btn>
        </div>

        <i class="iconfont icon-fenxiang" @click="share" v-if="data.id"></i>
      </div>

      <div style="flex: 1; height: 0;overflow: auto;">
        <div class="editor" ref="el" :key="editorKey" :style="{
          height: openEditor  ? (editorHeight + 'px') : 'auto'
        }">
          <editor v-model="data.content" :type="openEditor ? 'edit' : 'view'"/>
        </div>

        <div class="editor-action" v-if="hasEditor && openEditor" style="justify-content: space-between;display: flex;">
          <div>
            <fm-btn @click="save" v-loadingx="loading.save">保存</fm-btn>
            <fm-btn v-if="data.id" @click="openEditor = false">取消</fm-btn>
          </div>
          <template v-if="!data.id">
            <fm-poptip trigger="hover" position="top-end">
              <fm-btn @click="$refs['new-file'].choose()" v-loadingx="loading.upload">上传附件</fm-btn>
              <task-new-file slot="content" ref="new-file" />
            </fm-poptip>
          </template>
        </div>

        <template v-if="data.id">
          <div class="files">
            <files :root="'/task/' + (data.id ? data.id : 'temp')" label="附件"></files>
          </div>

          <div class="points-title">全部</div>
          <task-points ref="points" :id="data.id ? data.id : null"></task-points>

          <div class="replay">
            <replay-form @add-replay="$refs.points.loadData()" :task-id="data.id ? data.id : null"></replay-form>
          </div>
        </template>
      </div>

      <div class="info-actions">
        <!-- 编辑器底部固定位 -->
      </div>
    </div>
    <div class="actions">
      <div class="header">
        基本信息
        <fm-btn v-if="!isPage" @click="close" style="margin-right: 10px;">关闭</fm-btn>
      </div>
      <div class="content">
        <fm-form label-width="100px" label-align="left">
          <fm-form-item label="主数据">
            <task-data :confirm="!isNew" :task="data" @change="(field, data) => onFieldChange(field, data)"/>
          </fm-form-item>
          <fm-form-item label="紧急度">
            <task-priority :confirm="!isNew" @change="(data) => onFieldChange('priority', data)" :priority="data.priority"></task-priority>
          </fm-form-item>
          <fm-form-item label="类型">
            <task-type :confirm="!isNew" @change="(data) => onFieldChange('type', data)" :type="data.type"></task-type>
          </fm-form-item>
          <fm-form-item label="状态">
            <task-status :confirm="!isNew" @change="(data) => onFieldChange('status', data)" :status="data.status"></task-status>
          </fm-form-item>
          <fm-form-item label="执行人">
            <task-user :confirm="!isNew" @change="(data) => onFieldChange('executeUserId', data)" :id="data.executeUserId"></task-user>
          </fm-form-item>
          <fm-form-item label="确认人">
            <task-user :confirm="!isNew" @change="(data) => onFieldChange('confirmUserId', data)" :id="data.confirmUserId"></task-user>
          </fm-form-item>
          <fm-form-item label="分配日期">
            <task-time :confirm="true" :readonly="data.status === '已完成'" :value="data.assignTime" @setValue="(v) => onFieldChange('assignTime', v ? v.format('Y-M-D') : null)" />
          </fm-form-item>
          <fm-form-item label="截止日期">
            <task-time :confirm="!isNew" :readonly="data.status === '已完成'" :value="data.endTime" @setValue="(v) => onFieldChange('endTime', v ? v.format('Y-M-D') : null)" />
          </fm-form-item>
          <fm-form-item label="完成日期" v-if="data.status === '已完成'">
            <task-time readonly :value="data.finishTime || null" />
          </fm-form-item>
          <fm-form-item label="创建人">
            {{data.createUserName}}
          </fm-form-item>
          <fm-form-item label="创建日期">
            <task-time readonly :value="data.createTime" />
          </fm-form-item>
        </fm-form>
      </div>
    </div>
    <div class="menus">
      <i class="iconfont icon-zhankai1" :class="{stretch: stretch}" @click="stretch = !stretch"></i>
      <i class="iconfont icon-close" @click="close"></i>
    </div>
  </div>
</template>

<script>
import { taskRequest } from '@/api'
import Editor from '@/components/editor'
import TaskStatus from './field/status'
import TaskType from './field/type'
import TaskPriority from './field/priority'
import TaskTitle from './field/title'
import TaskTime from './select/time'
import TaskUser from './field/user'
import TaskData from "./select/data"
import Files from '@/views/file/index.vue'
import ReplayForm from './replay/form'
import TaskPoints from './points/index.vue'
import TaskNewFile from './select/file'

export default {
  components: { Editor, TaskStatus, TaskPriority, TaskType, TaskTitle, TaskTime, TaskUser, TaskData, Files, ReplayForm, TaskPoints, TaskNewFile },
  data () {
    return {
      data: {},
      stretch: false,
      openEditor: false,
      editorKey: null,
      editorHeight: 0,
      loading: {
        upload: false,
        del: false,
        save: false
      }
    }
  },
  props: {
    isPage: { type: Boolean, default: false },
    id: { type: Number, default: null },
    source: { type: Object, default: null }
  },
  watch: {
    source: {
      handler () {
        this.$set(this.data, 'dataType', this.source ? this.source.dataType : null)
        this.$set(this.data, 'dataId', this.source ? this.source.dataId : null)
        this.$set(this.data, 'item', this.source ? this.source.item : null)
      },
      deep: true,
      immediate: true
    },
    id: {
      async handler (id) {
        this.openEditor = false
        const task = id ? await taskRequest.details({id}) : null
        if (task && task.length) {
          this.data = task[0]
          this.endTime = this.data.endTime
          this.assignTime = this.data.assignTime
        } else {
          this.endTime = null
          this.assignTime = null
          this.data = {
            id: null,
            title: '',
            content: '',
            priority: '3',
            type: '开发',
            status: '规划中',
            executeUserId: null,
            confirmUserId: this.$store.getters.userInfo.id,
            createUserId: this.$store.getters.userInfo.id,
            createUserName: this.$store.getters.userInfo.name,
            dataType: this.source ? this.source.dataType : null,
            dataId: this.source ? this.source.dataId : null,
            item: this.source ? this.source.item : null,
            createTime: this.$datetime.format(new Date(), 'Y-M-D'),
            assignTime: this.$datetime.format(new Date(), 'Y-M-D'),
            endTime: null
          }
          this.openEditor = true
        }
      },
      immediate: true
    },
    editorFlag: {
      handler () {
        this.$nextTick(() => {
          setTimeout(() => {
            this.editorKey = this.editorFlag
          }, 300);
        })
      },
      immediate: true
    }
  },
  computed: {
    isNew () {
      if (this.data && this.data.id) {
        return false
      } else {
        return Boolean(this.id) === false
      }
    },
    editorFlag () {
      return (this.openEditor ? 'edit' : 'view') + String(this.stretch) + this.editorHeight + (this.data.id || '') + Math.random()
    },
    hasEditor () {
      return true
    }
  },
  methods: {
    share () {
      let url = location.origin + '/#/task?taskId=' + this.data.id
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(url).then(()  => {
          this.$notice.success('复制成功', url)
        }, (e) =>  {
          this.$notice.warning('复制失败' + e.message)
        });
      } else if (window.clipboardData) {
        window.clipboardData.setData('text',url)
        this.$notice.success('复制成功', url)
      } else {
        let oncopy = document.oncopy
        document.oncopy = (e) => {
          e.clipboardData.setData('text', url)
          e.preventDefault()
          document.oncopy = oncopy
          this.$notice.success('复制成功', url)
        }
        document.execCommand('copy')
      }
    },
    async del () {
      const res = await this.$dialog.confirm('确认要删除【' + this.data.title + '】吗?')
      if (res) {
        this.loading.del = true
        await taskRequest.del(this.data.id)
        this.loading.del = false
        this.close()
        this.$notice.success('删除成功')
        this.$emit('delete', this.data)
      }
    },
    async onFieldChange (field, data) {
      if (field === 'dataId' || this.data[field] !== data) {
        let fix = {}
        if (field === 'dataId') {
          if (this.data.dataId === data.dataId && this.data.dataType === data.dataType) {
            return
          } else {
            this.$set(this.data, 'dataId', data.dataId)
            this.$set(this.data, 'dataType', data.dataType)
            fix.dataType = data.dataType
            data = data.dataId
          }
        }
        
        if (field === 'status') {
          if (data === '已完成') {
            this.$set(this.data, 'finishTime', this.$datetime.format(new Date(), 'Y-M-D H:I:S'))
            fix.finishTime = this.data.finishTime
          } else {
            this.$set(this.data, 'finishTime', null)
            fix.finishTime = this.data.finishTime
          }
        }

        if (field === 'assignTime' && data) {
          if (this.data.endTime && new Date(data) > new Date(this.data.endTime)) {
            this.$set(this.data, 'assignTime', this.data.assignTime)
            this.$dialog.info('分配日期不能在截止日期之后')
            return
          }
          if (this.data.finishTime && new Date(data) > new Date(this.data.finishTime)) {
            this.$set(this.data, 'assignTime', this.data.assignTime)
            this.$dialog.info('分配日期不能在完成日期之后')
            return
          }
        }

        if (field === 'endTime' && data) {
          if (this.data.assignTime && new Date(data) < new Date(this.data.assignTime)) {
            this.$set(this.data, 'endTime', this.data.endTime)
            this.$dialog.info('截止日期不能在分配日期之前')
            return
          }
        }

        if (field === 'finishTime' && data) {
          if (this.data.assignTime && new Date(data) < new Date(this.data.assignTime)) {
            this.$set(this.data, 'finishTime', this.data.finishTime)
            this.$dialog.info('完成日期不能在分配日期之前')
            return
          }
        }

        this.$set(this.data, field, data)

        if (this.data.id) {
          await taskRequest.update(this.data.id, Object.assign({[field]: data}, fix))
          this.$refs.points.loadData()
          this.$emit('update', this.data.id, Object.assign({[field]: data}, fix))
        }
      }
    },
    close () {
      this.openEditor = false
      this.$emit('close')
    },
    async save () {
      try {
        if (!this.data.title) {
          throw new Error('标题不能为空')
        }
        if (!this.data.priority) {
          throw new Error('请选择紧急度')
        }
        if (!this.data.type) {
          throw new Error('请选择类型')
        }
        if (!this.data.status) {
          throw new Error('请选择状态')
        }
      } catch (error) {
        this.$notice.warning(error.message)
        return false
      }
      this.loading.save = true
      if (this.data.id) {
        await taskRequest.update(this.data.id, {content: this.data.content})
        this.$emit('update', this.data)
      } else {
        const res = await taskRequest.add(this.data)

        this.loading.upload = true
        await this.$refs['new-file'].upload(res.id)
        this.loading.upload = false

        this.$set(this.data, 'id', res.id)
        this.$emit('add', this.data)
      }

      this.openEditor = false
      this.loading.save = false

      this.$nextTick(() => {
        this.$refs.points.loadData()
      })
    },
    resize () {
      this.editorHeight = window.innerHeight - (this.$refs.header.offsetHeight + 56 + 48) + (this.data.id ? 30 : 0)
    }
  },
  mounted () {
    this.resize()
    window.addEventListener('resize', this.resize)
  },
  destroyed () {
    window.removeEventListener('resize', this.resize)
  }
}
</script>
