<style scoped lang="less">
  .history {
    color: #657180;
    word-break: break-all;
    padding-left: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &::before {
      display: inline-block;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #a2a2a2;
      border: 2px solid #FFF;
      box-sizing: border-box;
      position: absolute;
      left: -5px;
      top: 5px;
      transform: translateX(6%);
    }
    .tim {
      width: 150px;
    }
    .wrap {
      flex: 1;
      word-break: break-all;
    }
  }
</style>

<template>
  <div class="history">
    <div class="wrap">
      <span>{{data.username}}</span>
      <span v-html="label"></span>
    </div>
    <span class="tim">{{$datetime.format(data.createTime, 'Y-M-D H:I')}}</span>
  </div>
</template>

<script>
import { taskPriorityList, dataTypes } from '../lib'

export default {
  props: {
    data: { type: Object, default: null }
  },
  computed: {
    userList () {
      return this.$store.getters.userList
    },
    label () {
      try {
        if (this.data.method === 'POST') {
          return '创建了任务'
        }
        let params = JSON.parse(this.data.params)
        return Object.keys(params[2]).filter(v => v !== 'dataType').map(key => {
          if (key === 'content') {
            return '修改了任务详情'
          }
          if (key === 'dataId') {
            return '修改了主数据'
          }
          let item = this.getItem(key, params[2][key])
          return '将 <span style="color: #000;font-weight: 800;">' + item.title + '</span> 修改为 <span style="color: #000;font-weight: 800;">' + item.label + '</span>'
        }).join(',')
      } catch (error) {
        return '解析失败: ' + error.message        
      }
    }
  },
  methods: {
    getItem (key, value) {
      let fields = [
        { title: '标题', field: 'title' },
        { title: '分类', field: 'type' },
        { title: '主数据名称', field: 'dataId' },
        { title: '主数据类型', field: 'dataType' },
        { title: '优先级', field: 'priority' },
        { title: '状态', field: 'status' },
        { title: '处理人', field: 'executeUserId' },
        { title: '确认人', field: 'confirmUserId' },
        { title: '截止日期', field: 'endTime' },
        { title: '完成日期', field: 'finishTime' },
        { title: '分配日期', field: 'assignTime' }
      ]

      let title = (fields.find(v => v.field === key) || {title: '无匹配字段'}).title
      let label = null
      
      if (key === 'priority') {
        label = (taskPriorityList.find(v => v.value === value) || {label: '其他'}).label
      } else if (['confirmUserId', 'executeUserId'].includes(key)) {
        label = (this.userList.find(v => v.key === value) || {label: '无' + title}).label
      } else if (['assignTime', 'endTime'].includes(key)) {
        label = value ? this.$datetime.format(value, 'Y年M月D日') : ('无' + title)
      } else if (key === 'finishTime') {
        label = value ? this.$datetime.format(value, 'Y年M月D日 H:I') : ('无' + title)
      } else if (key === 'dataType') {
        label = (dataTypes.find(v => v.value === value) || {label: '其他'}).label
      } else if (key === 'dataId') {
        label = value
      } else {
        label = value
      }
      return { title, label }
    }
  }
}
</script>
