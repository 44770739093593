<style lang="less">
  @import './styles.less';
  .task-source-panel.task-source-person {
    .title {
      .fm-poptip {
        .fm-down-select-item {
          width: 230px;
        }
        .fmico.fmico-paixu-jiangxu {
          font-size: 14px;
        }
      }
    }
  }
</style>

<style lang="less" scoped>
  .line {
    border: 1px solid transparent;
    background-color: #e7e8ea;
    border-radius: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 15px;
    .value {
      color: #657180;
      z-index: 1;
    }
    .bar {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      border-radius: 20px;
      background-color: #2F5BEA;
      &::after {
        content: attr(data-value);
        color: #2F5BEA;
        position: absolute;
        top: -20px;
        right: 0;
        transition: all .3s;
        opacity: 0;
      }
    }
    &:hover .bar::after {
      opacity: 1;
      top: -25px;
    }
  }
</style>

<template>
  <div class="task-source-panel task-source-person">
    <div class="header">
      <div class="title" :data-source="sourceLabel">
        <fm-down-select :value="taskDataTheme[0].value" :arrow="false" @change="onDataThemeChange" :options="taskDataTheme" position="bottom-start">
          <div style="cursor: pointer;display: flex; align-items: center;">{{title}} <i class="fmico fmico-paixu-jiangxu" style="margin-left: 10px;"></i></div>
        </fm-down-select>
      </div>
      <div class="info">
        <fm-form label-width="100px" label-align="left">
          <fm-form-item label="本周待完成">{{weekStatic.stay}}</fm-form-item>
          <!-- <fm-form-item label="本周已完成">{{weekStatic.over}}</fm-form-item> -->
          <!-- <fm-form-item label="待确认">{{weekStatic.confirm}}</fm-form-item> -->
          <!-- <fm-form-item label="遗留任务">{{weekStatic.left}}</fm-form-item> -->
          <fm-form-item label="延误任务">{{weekStatic.delay}}</fm-form-item>
          <!-- <fm-form-item label="待完成:">
            <template v-if="pendingNum">
              <span>{{pendingNum}}</span>
              <template v-if="priorityNum['1']">
               / (<span title="紧急">{{priorityNum['1']}}急</span>)
              </template>
            </template>
            <template v-else>暂无</template>
          </fm-form-item>
          <fm-form-item label="已完成:"><span>{{statusNum['已完成'] || '暂无'}}</span></fm-form-item> -->
          <fm-form-item label="完成率:">
            <div style="height: 32px;display: flex; align-items: center;">
              <div class="line">
                <div class="bar" :data-value="weekStatic.completionRate + '%'" :style="{width: weekStatic.completionRate + '%'}"></div>
              </div>
            </div>
          </fm-form-item>
        </fm-form>
      </div>
    </div>
    <!-- 任务源列表 -->
    <div class="source-list">
      <ul :class="{'source-item-details': dataId && dataType}">
        <li v-for="(source, i) in sourceList" :key="source.dataId + source.dataType + i" :class="{
          active: ((dataType && dataId && source.dataType && source.dataId) && (dataType === source.dataType && dataId === source.dataId))
        }" @click="sourceSwitch(source)">
          <div class="wrap">
            <div class="type">{{getDataType(source.dataType)}}</div>
            <span style="margin: 0 5px">/</span>
            <div class="name">{{getDataLabel(source)}}</div>
          </div>
          <div class="num">
            <span class="pending">待完成: {{source.pendingNum}}</span>
            <!-- <span v-if="source.emergencyNum" class="emergency" title="紧急">紧急: {{source.emergencyNum}}</span> -->
          </div>
        </li>
      </ul>
      <div v-if="dataId && dataType" :key="dataId + dataType">
        <main-data :dataId="dataId" :dataType="dataType"></main-data>
      </div>
    </div>
  </div>
</template>

<script>
import { taskRequest } from '@/api'
import { dataTypes, getDataLabel, getDataSource, taskDataTheme } from '../lib'
import MainData from './main_data'

function getPendingNum (statusNum) {
  return ['规划中', '执行中', '待确认'].reduce((a, b) => {
    return a + (statusNum[b] ? statusNum[b] : 0)
  }, 0)
}

export default {
  props: {
    id: { type: Number, default: null },
    type: { type: String, default: null },
    query: { type: Object, default: null }
  },
  components: {
    MainData
  },
  data () {
    return {
      dataId: this.id ? Number(this.id) : null,
      dataType: this.type ? this.type : null,
      currentPane: taskDataTheme[0],
      source: null,
      dataList: [],
      priorityNum: {},
      statusNum: {},
      weekStatic: {
        stay: 0,
        confirm: 0,
        over: 0,
        left: 0,
        delay: 0,
        completionRate: 0
      }
    }
  },
  watch: {
    id () {
      this.dataId = this.id ? Number(this.id) : null
    },
    type () {
      this.dataType = this.type ? this.type : null
    },
    query () {
      this.loadData()
      this.loadStatistics()
    },
    sourceFlag () {
      this.loadSourceInfo()
    }
  },
  computed: {
    taskDataTheme () {
      return taskDataTheme
    },
    title () {
      return this.currentPane.label
    },
    sourceLabel () {
      return this.source ? ('(' + getDataLabel(this.source) + ')') : ''
    },
    sourceFlag () {
      return this.dataId + '-' + this.dataType
    },
    sourceList () {
      return this.dataList
    },
    pendingNum () {
      return getPendingNum(this.statusNum)
    }
  },
  methods: {
    onDataThemeChange (value) {
      this.currentPane = this.taskDataTheme.find(v => v.value === value)
      this.$emit('data-theme-change', this.currentPane)
    },
    async loadSourceInfo () {
      if (this.dataType && this.dataId) {
        let res = await getDataSource(this.dataType, this.dataId)
        if (res.length) {
          this.source = res[0]
        } else {
          this.source = null
        }
      } else {
        this.source = null
      }
    },
    async loadStatistics () {
      let now = new this.$datetime.Date()
      let day = now.getDay()
      day = day === 0 ? 7 : day
      let weekStart = day === 1 ? 0 : (day - 1)
      let weekEnd = day === 7 ? 0 : (7 - day)
      weekStart = now.compute('D', -weekStart).format('Y-M-D 00:00:00')
      weekEnd = now.compute('D', weekEnd).format('Y-M-D 00:00:00')

      let query = JSON.parse(JSON.stringify(this.query))
      // 删除不需要的过滤条件
      Object.keys(query).forEach(key => {
        if (!['executeUserId', 'createUserId'].includes(key)) {
          delete query[key]
        }
      })
      query = JSON.stringify(query)

      // 本周待完成
      let weekStay = taskRequest.count(Object.assign(JSON.parse(query), {
        statusList: '规划中,执行中,待确定',
        minEndTime: weekStart,
        maxEndTime: weekEnd
      }))

      // 本周待完成
      let weekConfirm = taskRequest.count(Object.assign(JSON.parse(query), {
        statusList: '待确定',
        minEndTime: weekStart,
        maxEndTime: weekEnd
      }))
      
      // 本周已完成
      let weekOver = taskRequest.count(Object.assign(JSON.parse(query), {
        statusList: '已完成',
        minEndTime: weekStart,
        maxEndTime: weekEnd
      }))

      // 遗留任务
      let weekLeft = taskRequest.count(Object.assign(JSON.parse(query), {
        statusList: '规划中,执行中,待确定',
        maxEndTime: weekStart
      }))

      // 延误任务
      let weekDelay = taskRequest.count(Object.assign(JSON.parse(query), {
        statusList: '规划中,执行中,待确定',
        maxEndTime: new this.$datetime.Date().format('Y-M-D 00:00:00')
      }))

      weekStay = await weekStay
      weekConfirm = await weekConfirm
      weekOver = await weekOver
      weekLeft = await weekLeft
      weekDelay = await weekDelay

      var nums = (a, b) => {
        return a + b.statusNum.reduce((c, d) => c + d.num, 0)
      }

      this.weekStatic = {
        stay: weekStay.reduce(nums, 0),
        over: weekOver.reduce(nums, 0),
        left: weekLeft.reduce(nums, 0),
        delay: weekDelay.reduce(nums, 0),
        confirm: weekConfirm.reduce(nums, 0)
      }

      let completionRate = Number((this.weekStatic.over / (this.weekStatic.stay + this.weekStatic.over + this.weekStatic.left)) * 100).toFixed(0)
      this.$set(this.weekStatic, 'completionRate', isNaN(completionRate) ? 0 : completionRate)
    },
    sourceSwitch (source) {
      if (source.dataId !== null && source.dataType !== null && source.dataId === this.dataId && source.dataType === this.dataType) {
        this.dataId = null
        this.dataType = null
        this.$emit('source-switch', null)
      } else {
        this.dataId = source.dataId
        this.dataType = source.dataType
        this.$emit('source-switch', source)
      }
    },
    getDataLabel (task) {
      return getDataLabel(task)
    },
    getDataType (type) {
      let res = dataTypes.find(v => v.value === type)
      return res ? res.label : '其他'
    },
    async loadData () {
      let query = JSON.parse(JSON.stringify(this.query))
      
      // 删除不需要的过滤条件
      Object.keys(query).forEach(key => {
        if (!['executeUserId', 'createUserId'].includes(key)) {
          delete query[key]
        }
      })

      let dataList = await taskRequest.count(query)
      let priorityNum = {}
      let statusNum = {}

      dataList.forEach(v => {
        v.priorityNum.forEach(i => {
          if (priorityNum[i.status] === undefined) {
            priorityNum[i.status] = 0
          }
          priorityNum[i.status] += i.num
        })
        let vStatusNum = {}
        v.statusNum.forEach(i => {
          if (statusNum[i.status] === undefined) {
            statusNum[i.status] = 0
          }
          if (vStatusNum[i.status] === undefined) {
            vStatusNum[i.status] = 0
          }
          vStatusNum[i.status] += i.num
          statusNum[i.status] += i.num
        })
        // 任务源待完成数量
        v.pendingNum = getPendingNum(vStatusNum)
        // 任务源已完成数量
        v.completionNum = (v.statusNum.find(s => s.status === '已完成') || {num: 0}).num
        // 任务源紧急数量
        v.emergencyNum = (v.priorityNum.find(p => p.priority === '1') || {num: 0}).num
      })

      // 全局紧急度
      this.priorityNum = priorityNum
      // 全局各状态
      this.statusNum = statusNum

      this.dataList = dataList.sort((a, b) => b.pendingNum - a.pendingNum)
    }
  },
  mounted () {
    this.loadData()
    this.loadStatistics()
    this.loadSourceInfo()
  }
}
</script>
