import store from '@/store'

import fileConf from '@/syslib/file_conf'

import TextInfo from '@/components/datainfo/TextInfo'
import TypeItem from '@/components/datainfo/TypeItem'
import FileInfo from '@/components/datainfo/FileInfo'

import {
  appVersionList
} from './app_version'

import {
  appServiceList
} from './app_service'

import {
  appRequest
} from '@/api'

let formParms = [{
  type: 'input',
  label: '名称',
  key: 'name',
  check: {
    required: true
  }
},
{
  type: 'select',
  label: '类型',
  key: 'type',
  selectDatas: store.getters.appTypeList
},
{
  type: 'textarea',
  label: '说明',
  key: 'description'
}]

export const appList = function (parm = {}) {
  let config = {
    key: parm.key || 'app',
    titleConfig: {
      title: parm && parm.title ? parm.title : '应用',
      titleMenus: [{
        key: 'add',
        label: '新增'
      }]
    },
    listConfig: {
      filterFun (data, parm) {
        return data.filter((v) => {
          let need = parm ? v.name.includes(parm) : true
          return need
        })
      },
      filterParm: [{
        type: 'input',
        placeholder: '名称',
        key: 'name'
      },
      {
        type: 'select',
        placeholder: '类型',
        selectDatas: store.getters.appTypeList,
        key: 'type'
      }],
      listMenus: [
        {
          key: 'task',
          label: '相关任务',
          dealFun (data, vm) {
            vm.$router.push({
              name: 'task', query: {
                type: 'app',
                id: data.id
              }
            })
          }
        },
        {
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }
      ],
      config: app(),
      getTitle: (data) => {
        return data.name
      }
    },
    async getData (dataParm) {
      let data = await appRequest.get(dataParm)
      data.forEach((item) => {
        item.__showNum = 0
      })
      return data
    },
    updateConfig: {
      title: '应用',
      request: appRequest,
      getFormParms () {
        return formParms
      }
    }
  }
  if (parm.openConf) {
    config.openConf = parm.openConf
  }
  return config
}

export const app = function ({defaultOpen} = {}) {
  let config = {
    defaultOpen: defaultOpen || false,
    async getData (dataParm) {
      if (!dataParm || !dataParm.id) {
        return Promise.resolve({})
      }
      let data = await appRequest.get({id: dataParm.id})
      return data.length > 0 ? data[0] : null
    },
    getTitle: (data) => {
      return data ? data.name : ''
    },
    getInfoParm: (data) => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: [{
            label: '说明',
            field: 'description'
          },
          {
            label: '类型',
            field: 'type'
          }]
        }
      },
      {
        comp: TypeItem,
        dataParm: {
          appId: data.id
        },
        config: appServiceList({}, {})
      },
      {
        comp: TypeItem,
        dataParm: {
          appId: data.id
        },
        config: appVersionList()
      },
      {
        comp: FileInfo,
        config: {
          filePath: fileConf.app ? fileConf.app.getPath(data) : null
        }
      }]
    }
  }
  return config
}
