<style scoped>
  [class^="g-block-badge-"] {
    cursor: pointer;
  }
</style>

<template>
  <priority-select @change="onChange" :confirm="confirm" :clearable="clearable">
    <div :class="cls">{{label}}</div>
  </priority-select>
</template>

<script>
import PrioritySelect from '../select/priority'
import { taskPriorityList } from '../lib'

export default {
  components: { PrioritySelect },
  data () {
    return {
      data: this.priority
    }
  },
  props: {
    priority: {},
    confirm: { type: Boolean, default: true },
    clearable: { type: Boolean, default: true }
  },
  watch: {
    priority () {
      this.data = this.priority
    }
  },
  computed: {
    match () {
      return taskPriorityList.find(v => v.value === this.data)
    },
    label () {
      return this.match ? this.match.label : '无'
    },
    cls () {
      return this.match ? this.match.cls : 'g-block-badge-default'
    }
  },
  methods: {
    onChange (data) {
      this.data = data
      this.$emit('change', data)
    }
  }
}
</script>
