<style scoped lang="less"></style>

<style lang="less"></style>

<template>
  <fm-poper-select v-bind="$attrs" class="user-select" @change="onChange" :options="options">
    <slot></slot>
  </fm-poper-select>
</template>

<script>
export default {
  props: {},
  computed: {
    userList () {
      return this.$store.getters.userList
    },
    options () {
      return this.userList.map(user => {
        return { label: user.label, value: user.key }
      })
    }
  },
  methods: {
    onChange (v) {
      this.$emit('change', v)
    }
  },
  async mounted () {
    await this.$store.dispatch('loadUserList')
  }
}
</script>
