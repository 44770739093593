<style lang="less">
  .task-time {
    input {
      background-color: transparent;
    }
  }
</style>

<template>
  <div class="task-time">
    <fm-date-picker
      style="width: 90px;"
      type="date"
      text
      :key="key"
      @change="() => key++"
      v-on="$listeners"
      v-bind="$attrs"
      placeholder="日期"
      format="M月D日"
    ></fm-date-picker>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      key: 0
    }
  }
}
</script>
