<style lang="less">
  .task-lists {
    position: relative;
    height: 100%;
    .fm-table-border-row .fm-table-head-cells {
      border-top-color: transparent;
    }
    .fm-table-head-cells {
      background-color: #FFF;
    }
  }
</style>

<template>
  <div class="task-lists">
    <fm-table-new
      :key="loadKey"
      :full="true"
      auto-height
      border="row"
      @row-dbclick="rowDbClick"
      :stripe="false"
      :columns="columns"
      :data-list="dataList"
    ></fm-table-new>
  </div>
</template>

<script>
import { taskRequest } from '@/api'
import TaskStatus from './field/status'
import TaskPriority from './field/priority'
import TaskType from './field/type'
// import TaskTitle from './field/title'
import TaskUser from './field/user'
import TaskTime from './select/time'
import TaskData from './select/data'

import { taskStatusList } from './lib'

export default {
  data () {
    return {
      lists: [],
      loadKey: 0
    }
  },
  props: {
    query: { type: Object, default: null }
  },
  computed: {
    columns () {
      return [
        {
          title: '标题', field: 'title', align: 'left', width: 190,
          sort: true,
          dataType: String,
          render: (h, row) => {
            return h('div', {
              style: {
                cursor: 'pointer'
              },
              on: {
                click: (e) => {
                  e.stopPropagation();
                  this.$emit('show-details', row.id)
                }
              }
            }, row.title)
            // return h(TaskTitle, {
            //   props: {
            //     title: row.title
            //   },
            //   on: {
            //     change: title => {
            //       this.onFieldChange(row.id, 'title', title)
            //     }
            //   }
            // })
          }
        },
        {
          title: '分类', 
          sort: true,
          dataType: String,
          field: 'type', align: 'left', width: 100, render: (h, row) => {
            return h(TaskType, {
              props: {
                clearable: false,
                type: row.type,
                position: null
              },
              on: {
                change: type => {
                  type !== row.type && this.onFieldChange(row.id, 'type', type)
                }
              }
            })
          }
        },
        {
          title: '所属',
          sort: true,
          dataType: String,
          field: 'dataTypeDataId',
          align: 'left', width: 120, render: (h, row) => {
            return h(TaskData, {
              props: {
                task: row,
                confirm: true
              },
              on: {
                change: (field, data) => {
                  if (row.dataId !== data.dataId && row.dataType !== data.dataType) {
                    this.onFieldChange(row.id, 'dataId', data)
                  }
                }
              }
            })
          }
        },
        {
          title: '优先级', field: 'priority', align: 'left', width: 100,
          sort: true,
          dataType: Number,
          render: (h, row) => {
            return h(TaskPriority, {
              props: {
                clearable: false,
                priority: row.priority
              },
              on: {
                change: priority => {
                  priority !== row.priority && this.onFieldChange(row.id, 'priority', priority)
                }
              }
            })
          }
        },
        {
          title: '状态', field: 'statusNum', align: 'left', width: 100,
          sort: true,
          dataType: Number,
          render: (h, row) => {
            return h(TaskStatus, {
              props: {
                clearable: false,
                status: row.status
              },
              on: {
                change: status => {
                  status !== row.status && this.onFieldChange(row.id, 'status', status)
                }
              }
            })
          }
        },
        { title: '处理人', field: 'executeUserId', align: 'left', width: 80,
          sort: true,
          dataType: Number,
          render: (h, row) => {
            return h(TaskUser, {
              attrs: {
                confirm: true,
                clearable: false
              },
              props: {
                id: row.executeUserId
              },
              on: {
                change: executeUserId => {
                  executeUserId !== row.executeUserId && this.onFieldChange(row.id, 'executeUserId', executeUserId)
                }
              }
            })
          }
        },
        { title: '确认人', field: 'confirmUserId', align: 'left', width: 80,
          sort: true,
          dataType: Number,
          render: (h, row) => {
            return h(TaskUser, {
              attrs: {
                confirm: true,
                clearable: false
              },
              props: {
                id: row.confirmUserId
              },
              on: {
                change: confirmUserId => {
                  confirmUserId !== row.confirmUserId && this.onFieldChange(row.id, 'confirmUserId', confirmUserId)
                }
              }
            })
          }
        },
        {
          title: '分配日期', field: 'assignTimeNum', align: 'left',
          sort: true,
          dataType: Number,
          width: 90,
          render: (h, row) => {
            return h(TaskTime, {
              attrs: {
                value: row.assignTime
              },
              on: {
                setValue: assignTime => {
                  let tim = assignTime.format('Y-M-D H:I:S')
                  if (tim !== row.assignTime) {
                    if (assignTime) {
                      if (row.endTime && new Date(assignTime) > new Date(row.endTime)) {
                        this.$dialog.info('分配日期不能在截止日期之后')
                        return
                      }
                      if (row.finishTime && new Date(assignTime) > new Date(row.finishTime)) {
                        this.$dialog.info('分配日期不能在完成日期之后')
                        return
                      }
                    }
                    this.onFieldChange(row.id, 'assignTime', tim)
                    row.assignTime = tim
                  }
                }
              }
            })
          }
        },
        {
          title: '截止日期', field: 'endTimeNum', align: 'left',
          width: 90,
          sort: true,
          dataType: Number,
          render: (h, row) => {
            return h(TaskTime, {
              attrs: {
                value: row.endTime
              },
              on: {
                setValue: endTime => {
                  let tim = endTime.format('Y-M-D H:I:S')
                  if (tim !== row.endTime) {
                    if (endTime) {
                      if (row.assignTime && new Date(endTime) < new Date(row.assignTime)) {
                        this.$dialog.info('截止日期不能在分配日期之前')
                        return
                      }
                    }
                    this.onFieldChange(row.id, 'endTime', tim)
                    row.endTime = tim
                  }
                }
              }
            })
          }
        }
      ]
    },
    dataList () {
      return this.lists
    }
  },
  watch: {
    query () {
      this.loadData()
    }
  },
  methods: {
    async onFieldChange (id, field, data) {
      let fix = {}
      if (field === 'status') {
        if (data === '已完成') {
          fix.finishTime = this.$datetime.format(new Date(), 'Y-M-D H:I:S')
        } else {
          fix.finishTime = null
        }
      }
      if (field === 'dataId') {
        fix.dataType = data.dataType
        data = data.dataId
      }

      await taskRequest.update(id, Object.assign({[field]: data}, fix))
      this.$emit('update')
    },
    async loadData () {
      let data = await taskRequest.get(this.query)
      data.forEach((v) => {
        v.statusNum = taskStatusList.map(v1 => v1.value).indexOf(v.status)
        v.dataTypeDataId = v.dataType + '_' + v.dataId
        v.assignTimeNum = v.assignTime ? new Date(v.assignTime).getTime() : 0
        v.endTimeNum = v.endTime ? new Date(v.endTime).getTime() : 0
      })
      this.lists = data
    },
    rowDbClick ({row}) {
      this.$emit('show-details', row.id)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
