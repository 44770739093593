import { taskRequest } from '@/api'

export const taskTypeList = Object.freeze([
  {
    label: '需求对接',
    value: '需求对接'
  },
  {
    label: '功能模块设计',
    value: '功能模块设计'
  },
  {
    label: 'UI设计',
    value: 'UI设计'
  },
  {
    label: '开发',
    value: '开发'
  },
  {
    label: '部署',
    value: '部署'
  },
  {
    label: '测试',
    value: '测试'
  },
  {
    label: '发布',
    value: '发布'
  },
  {
    label: '运维',
    value: '运维'
  },
  {
    label: '其他',
    value: '其他'
  }
])

export const taskPriorityList = Object.freeze([
  {
    label: '紧急',
    value: '1',
    cls: 'g-block-badge-high'
  },
  {
    label: '优先',
    value: '2',
    cls: 'g-block-badge-mide'
  },
  {
    label: '普通',
    value: '3',
    cls: 'g-block-badge-low'
  }
])

export const taskStatusList = Object.freeze([
  {
    label: '规划中',
    value: '规划中',
    cls: 'g-label-badge-info'
  },
  {
    label: '执行中',
    value: '执行中',
    cls: 'g-label-badge-primary'
  },
  {
    label: '待确认',
    value: '待确认',
    cls: 'g-label-badge-waring'
  },
  {
    label: '已完成',
    value: '已完成',
    cls: 'g-label-badge-success'
  },
  {
    label: '作废',
    value: '作废',
    cls: 'g-label-badge-default'
  }
])

async function loadDataTypesAction (action) {
  let data = await this.$store.dispatch(action)
  return data.map(v => {
    return {label: v.label, value: v.key}
  })
}

export const dataTypes = Object.freeze([
  {
    value: 'org', label: '客户', load: function () {
      return loadDataTypesAction.bind(this)('loadOrgList')
    }
  },
  {
    value: 'sys', label: '系统', load: function () {
      return loadDataTypesAction.bind(this)('loadSysList')
    }
  },
  {
    value: 'sys_version', label: '系统版本', load: function () {
      return loadDataTypesAction.bind(this)('loadSysVersionList')
    }
  },
  {
    value: 'sys_instance', label: '应用系统', load: function () {
      return loadDataTypesAction.bind(this)('loadSysInstanceList')
    }
  },
  {
    value: 'app', label: '应用', load: function () {
      return loadDataTypesAction.bind(this)('loadAppList')
    }
  },
  {
    value: 'app_version', label: '应用版本', load: function () {
      return loadDataTypesAction.bind(this)('loadAppVersionList')
    }
  },
  {
    value: 'app_service', label: '应用服务', load: function () {
      return loadDataTypesAction.bind(this)('loadAppServiceList')
    }
  },
  {
    value: 'server', label: '服务器', load: function () {
      return loadDataTypesAction.bind(this)('loadServerList')
    }
  },
  {
    value: null, label: '其他', children: [{value: null, label: '其他'}]
  }
])

export const getDataLabel = function (task) {
  try {
    if (!task || !task.dataType || !task.dataId) {
      return '其他'
    }
    switch (task.dataType) {
      case 'app_version':
        return task.item.app.name + ' ' + task.item.versionNumber
      case 'sys_version':
        return task.item.sys.name + ' ' + task.item.versionNumber
      case 'app_service':
        return task.item.sys.name + ' ' + task.item.server.name
      case 'sys_instance':
        return (task.item.org ? task.item.org.name : task.item.user) + ' ' + task.item.sys.name + ' ' + task.item.type
      case 'sys':
      case 'org':
      case 'app':
      case 'server':
        return task.item.name
      default:
        return '无匹配数据类型'
    }  
  } catch (e) {
    return '解析失败'
  }
}

export async function getDataSource (type, id) {
  return await taskRequest.count({dataType: type, dataId: id})
}

export const taskDataTheme = Object.freeze([
  {
    label: '小黑板',
    value: 'blackboard',
    query: function () {
      return {}
    }
  },
  {
    label: '我的任务',
    value: 'woker',
    query: function () {
      return {
        executeUserId: this.$store.getters.userInfo.id
      }
    }
  },
  {
    label: '团队任务',
    value: 'group',
    query: function () {
      return {}
    }
  },
  {
    label: '我创建的',
    value: 'create',
    query: function () {
      return {
        createUserId: this.$store.getters.userInfo.id
      }
    }
  },
  {
    label: '待我确认',
    value: 'confirm',
    query: function () {
      return {
        confirmUserId: this.$store.getters.userInfo.id
      }
    }
  }
])

export const taskFilters = Object.freeze([
  { label: '客户', value: 'orgId', component: () => import('./field/org') },
  { label: '场景', value: 'scene', component: () => import('./select/scene') },
  { label: '状态', value: 'status', component: () => import('./field/status') },
  { label: '优先级', value: 'priority', component: () => import('./field/priority') },
  { label: '处理人', value: 'executeUserId', component: () => import('./field/user') },
  { label: '确认人', value: 'confirmUserId', component: () => import('./field/user') },
  { label: '创建人', value: 'createUserId', component: () => import('./field/user') },
  { label: '创建日期', value: 'createTime', component: () => import('./select/time') },
  { label: '分配日期', value: 'assignTime', component: () => import('./select/time') },
  { label: '完成日期', value: 'finishTime', component: () => import('./select/time') },
  { label: '截止日期', value: 'endTime', component: () => import('./select/time') }
])