<style scoped>
  .type {
    cursor: pointer;
  }
</style>

<style lang="less">
  .task-details, .task-lists {
    .fm-down-select-items {
      max-height: 200px;
      overflow: auto;
    }
  }
</style>

<template>
  <fm-poper-select @change="onChange" :options="options" :confirm="confirm" :position="position" :clearable="clearable">
    <div class="type">{{label}}</div>
  </fm-poper-select>
</template>

<script>
import { taskTypeList } from '../lib'
export default {
  data () {
    return {
      data: this.type
    }
  },
  props: {
    confirm: { type: Boolean, default: true },
    clearable: { type: Boolean, default: true },
    type: { default: null },
    position: { type: String, default: 'bottom' }
  },
  watch: {
    type () {
      this.data = this.type
    }
  },
  computed: {
    options () {
      return taskTypeList
    },
    label () {
      return this.data ? this.data : '未分类'
    }
  },
  methods: {
    onChange (data) {
      this.data = data
      this.$emit('change', data)
    }
  },
}
</script>
