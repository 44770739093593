<style scoped lang="less">
  input {display: none;}
  .task-file {
    padding: 10px;
  }
  ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
    }
    span, i {
      color: #657180;
    }
    i {
      font-size: 12px;
      cursor: pointer;
    }
    & + li {
      margin-top: 10px;
    }
    .loading {
      width: 15px;
      height: 15px;
    }
  }
</style>

<template>
  <div class="task-file">
    <input type="file" @change="change" ref="input" />
    <ul>
      <li v-for="(item, i) in files" :key="item.file.name + item.file.lastModified">
        <span>{{item.file.name}}</span>
        <i v-if="!item.loading" class="fmico fmico-cha" @click="remove(i)"></i>
        <img v-else class="loading" src="/static/images/icon/file/loading.gif" />
      </li>
      <li v-if="!files.length">
        <span>暂无附件!</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { fileRequest } from '@/api'

export default {
  data () {
    return {
      loading: false,
      files: []
    }
  },
  methods: {
    remove (i) {
      if (this.loading) {
        this.$notice.warning('请等待处理完成后进行操作')
      } else {
        this.files.splice(i, 1)
        this.$emit('remove', this.files)
      }
    },
    choose () {
      this.$refs.input.click()
    },
    change (e) {
      e.target.files.length && this.files.push({loading: false, file: e.target.files[0]})
      this.$emit('change', this.files)
    },
    async upload (id) {
      if (!this.files.length) {
        return Promise.resolve(true)
      }
      this.$emit('start')
      this.loading = true

      await fileRequest.mkdir({ path: '/task', name: id })

      await Promise.all(this.files.map(item => {
        return new Promise(async (resolve, reject) => {
          item.loading = true
          let parm = new FormData()
          parm.append('file', item.file)
          parm.append('path', '/task/' + id)
          try {
            await fileRequest.upload(parm)
            resolve(true)
          } catch (error) {
            item.loading = false
            reject(error)
          }
        })
      }))

      this.$emit('finish')
      this.loading = false

      return Promise.resolve(true)
    }
  }
}
</script>
