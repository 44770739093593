/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Thu Apr 21 2021
 */
import { taskFilters } from '../lib'
export default {
  data () {
    return {
      defaults: this.filters
    }
  },
  props: {
    filters: {
      type: Array, default () {
        return []
      }
    },
    value: {
      type: Object, default () {
        return {}
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      handler (n, o) {
        const restore = o.filter(v => !n.includes(v))
        restore.forEach(v => {
          this.onChange(v, this.defaults[v])
        })
      }
    }
  },
  methods: {
    onChange (field, data) {
      this.$emit('change', field, data)
    }
  },
  render: function (h) {
    return h('div', {
      style: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        wordBreak: 'keep-all'
      }
    }, this.filters.filter(v => taskFilters.findIndex(item => item.value === v) > -1).map(field => {
      let options = {
        props: {},
        attrs: {
          clearable: true,
          confirm: true
        },
        on: {},
        style: {
          marginRight: '10px'
        }
      }

      switch (field) {
        case 'executeUserId':case 'confirmUserId':case 'createUserId':case 'orgId':
          options.props.id = this.value[field]
          options.on.change = (data) => this.onChange(field, data)
          break
        case 'status':case 'priority':
          options.props[field] = this.value[field]
          options.props.multiple = true
          options.on.change = (data) => this.onChange(field, data)
          break
        case 'scene':
          options.props.value = this.value['scene']
          options.on.change = (data) => this.onChange('status', data)
          break
        case 'createTime':case 'assignTime':case 'finishTime':case 'endTime':
          options.attrs.value = this.value[field] ? this.value[field] : null
          options.on.setValue = (data) => this.onChange(field, data)
          options.props.placeholder = '日期'
          options.props.format = 'M月D日'
          options.props.type = 'daterange'
          options.props.text = true
          options.style.flex = 1
          break
      }
      
      const isTime = ['createTime', 'assignTime', 'finishTime', 'endTime'].includes(field)
      const item = taskFilters.find(item => item.value === field)
      return h('div', {
        style: {
          width: isTime ? '250px' : '30%',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          wordBreak: 'keep-all'
        }
      }, [
        h('span', {style:{marginRight: '5px', color: '#AAA'}}, item.label + ': '),
        h(isTime ? 'fm-date-picker' : item.component, options)
      ])
    }))
  }
}
