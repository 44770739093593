<style lang="less">
  .task-title {
    .fm-input-new {
      border-color: transparent;
      display: flex;
    }
    .fm-input-new.fm-input-focus, .fm-input-new:hover {
      border-color: #1cb5e0;
    }
    input {
      background-color: transparent;
    }
  }
</style>

<template>
  <div class="task-title">
    <fm-input-new :value="title" @blur="onChange" placeholder="请输入任务标题" />
  </div>
</template>

<script>
export default {
  props: {
    title: {}
  },
  methods: {
    onChange (v) {
      this.$emit('change', v)
    }
  }
}
</script>
