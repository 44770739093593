<style lang="less">
  .task-page {
    display: flex;
    flex-direction: column;
    background-color: #F8FBFF;
    .fm-tabs {
      background-color: #FFF;
    }
    .fm-tabs-content {
      display: none;
    }
    .task-header {
      .fm-select {
        width: unset;
      }
    }
    .task-panes {
      .fm-tabs-nav-item {
        text-align: center;
        width: 190px;
        height: 51px;
        line-height: 51px;
        padding: 0;
      }
    }
    .fm-down-select-items {
      max-height: 200px;
      overflow: auto;
    }
    .fm-down-select-item {
      font-size: 14px;
      padding: 5px 13px;
    }
    .fm-down-select-item-selected {
      color: #2F5BEA;
      background: rgba(47, 91, 234, .1);
    }
    .fm-down-select-multiple {
      .fm-down-select-item-selected {
        background: #FFF;
        .fm-down-select-option {
          color: #2F5BEA;
        }
      }
    }
  }
</style>

<style lang="less" scoped>
.task-wrap {
  box-sizing: border-box;
  margin: 15px;
  display: flex;
  align-items: flex-start;
  background-color: #FFF;
  flex: 1;
  height: 0;
}
.source {
  position: relative;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
}
.task-content {
  position: relative;
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  .task-header {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    justify-content: space-between;
    .center {
      flex: 1;
    }
    .left, .center, .right {
      display: flex;
      align-items: center;
    }
    .right {
      flex: 1;
      & > * + * {
        margin-left: 10px;
      }
    }
  }
  .task-footer {
    padding: 10px;
    .task-ft-btn {
      color: #707070;
      display: flex;
      align-items: center;
      cursor: pointer;
      &, span {
        transition: all .3s;
      }
      span {
        display: inline-block;
        background: rgba(50, 60, 71, 0.5);
        color: #FFF;
        margin-right: 9px;
        width: 24px;
        border-radius: 4px;
        text-align: center;
        line-height: 24px;
        height: 24px;
      }
      &:hover {
        & {
          color: #2F5BEA;
        }
        span {
          background-color: #2F5BEA;
        }
      }
      & + & {
        margin-left: 10px;
      }
    }
  }
  .task-list {
    height: 0;
    flex: 1;
  }
}
.detail-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 0 10px 0 #EEE;
  background-color: #FFF;
  box-sizing: border-box;
}
</style>

<template>
  <div class="task-page">
    <div class="task-wrap">
      <div class="source">
        <task-source @source-switch="sourceSwitch" @data-theme-change="dataThemeChange" :query="query" ref="source"></task-source>
      </div>
      <div class="task-content">
        <black-board v-if="openBlackboard === true" />
        <template v-else>
          <div class="task-header">
            <div class="left">
              <fm-input-new placeholder="请输入搜索内容" @enten="(v) => searchKey = v" @blur="(v) => searchKey = v">
                <i class="fmico fmico-search" slot="suffix"></i>
              </fm-input-new>
            </div>
            <div class="right">
              <task-filters :value="filters" @change="onFiltersChange" />
              <fm-btn type="primary" @click="create">+创建任务</fm-btn>
            </div>
          </div>
          <div class="task-list">
            <task-lists ref="lists" @show-details="showDetails" @update="sourceReload" :query="query"></task-lists>
          </div>
          <div class="task-footer" style="display: none;">
            <div class="task-ft-btn" @click="create">
              <span>+</span>
              创建任务
            </div>
          </div>
        </template>
      </div>
      <div class="detail-modal" v-if="detailsId !== null">
        <task-detail ref="detail" :id="detailsId" @close="detailsId = null" :source="source" @add="pageReload" @update="pageReload" @delete="pageReload"></task-detail>
      </div>
    </div>
  </div>
</template>

<script>
import TaskSource from './source'
import TaskLists from './lists'
import TaskDetail from './details'
import TaskFilters from './filters'
import { getDataSource, taskDataTheme } from './lib'
import BlackBoard from './blackboard'

import {
  localstorageHelper
} from '@/fmlib'

export default {
  components: {
    TaskSource, TaskLists, TaskDetail, TaskFilters, BlackBoard
  },
  data () {
    return {
      detailsId: null,
      searchKey: null,
      searchDate: null,
      currentPane: taskDataTheme[0],
      source: null,
      openBlackboard: taskDataTheme[0].value === 'blackboard',
      filters: {
        status: ['规划中', '执行中', '待确认'],
        scene: '待处理',
        priority: null,
        executeUserId: null,
        confirmUserId: null,
        createUserId: null,
        orgId: null,
        createTime: null,
        assignTime: null,
        finishTime: null,
        endTime: null
      }
    }
  },
  activated () {
    this.$store.dispatch('setIsPop', false)
  },
  deactivated () {
    this.$store.dispatch('setIsPop', localstorageHelper.getData('is_pop') === '1')
    this.detailsId = null
  },
  computed: {
    routerTaskId () {
      return this.$route.query.taskId ? Number(this.$route.query.taskId) : null
    },
    routerDataId () {
      return this.$route.query.id ? Number(this.$route.query.id) : null
    },
    routerDataType () {
      return this.$route.query.type
    },
    query () {
      let paneQuery = this.currentPane.query.call(this)
      let routerQuery = {}
      let conditionQuery = { statusList: this.filters.status.join(','), title: this.searchKey }

      Object.keys(this.filters).filter(v => !['status'].includes(v)).forEach(item => {
        if (this.filters[item] !== null) {
          const base = item.charAt(0).toUpperCase() + item.substr(1)
          switch (item) {
            case 'createTime':case 'assignTime':case 'finishTime':case 'endTime':
              conditionQuery['min' + base] = this.filters[item][0]
              conditionQuery['max' + base] = this.filters[item][1]
              break
            default:
              conditionQuery[item] = this.filters[item]
          }
        }
      })

      if (this.searchDate && this.searchDate.length >= 2) {
        conditionQuery.minEndTime = this.searchDate[0].format('Y-M-D')
        conditionQuery.maxEndTime = this.searchDate[1].compute('d', +1).format('Y-M-D')
      }

      if (this.$route.query && this.$route.query.id && this.$route.query.type) {
        routerQuery.dataId = this.$route.query.id
        routerQuery.dataType = this.$route.query.type
      }

      if (this.source) {
        return Object.assign(paneQuery, conditionQuery, {
          dataType: this.source.dataType || 'null',
          dataId: this.source.dataId || 'null'
        })
      } else {
        return Object.assign(paneQuery, conditionQuery, routerQuery, {})
      }
    }
  },
  watch: {
    routerDataId: {
      immediate: true,
      handler () {
        this.loadSourceByRoute()
      }
    },
    routerDataType: {
      immediate: true,
      handler () {
        this.loadSourceByRoute()
      }
    },
    routerTaskId: {
      immediate: true,
      handler (v) {
        if (v) {
          this.showDetails(v)
          this.$nextTick(() => {
            this.$refs.detail.stretch = true
          })
        }
      }
    }
  },
  methods: {
    onFiltersChange (field, data) {
      switch (field) {
        case 'executeUserId':case 'confirmUserId':case 'createUserId':case 'orgId':case 'status':case 'priority':
          this.filters[field] = data
          break
        case 'createTime':case 'assignTime':case 'finishTime':case 'endTime':
          if (Array.isArray(data)) {
            this.filters[field] = data
          } else if (data) {
            const base = this.$datetime.strToDate(data)
            this.filters[field] = [base.format('Y-M-D 00:00:00'), base.compute('d', 1).format('Y-M-D 00:00:00')]
          } else {
            this.filters[field] = null
          }
          break
      }
    },
    dataThemeChange (theme) {
      if (theme.value === 'blackboard') {
        this.openBlackboard = true
      } else {
        this.openBlackboard = false
        this.currentPane = theme
      }
    },
    sourceSwitch (source) {
      this.source = source
    },
    create () {
      this.showDetails(0)
    },
    showDetails (id) {
      this.detailsId = id
    },
    pageReload () {
      this.$refs.lists.loadData()
      this.$refs.source.loadData()
    },
    sourceReload () {
      this.$refs.source.loadData()
    },
    async loadSourceByRoute () {
      this.detailsId = null
      if (this.routerDataType && this.routerDataId) {
        let res = await getDataSource(this.routerDataType, this.routerDataId)
        if (res.length) {
          this.source = res[0]
        } else {
          this.source = null
        }
      } else {
        this.source = null
      }
    }
  }
}
</script>