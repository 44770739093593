<style lang="less">
  .task-data {
    .task-data-label {
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .task-poper-select {
      height: 300px;
    }
    .fm-cascader {
      display: flex;
      &.fm-cascader-open .fm-cascader-wrap {
        border-radius: 5px;
      }
      .fm-cascader-wrap, .fm-cascader-input {
        width: 100%;
      }
      .fm-cascader-section {
        border-color: #EEE;
        box-shadow: none;
        ul {
          max-height: 250px;
          overflow-y: auto;
        }
      }
    }
  }
</style>

<template>
  <div class="task-data">
    <div class="task-data-label" @click="show = !show">{{label}}</div>
    <fm-modal v-model="show">
      <div class="task-poper-select">
        <fm-cascader
          ref="cascader"
          :data-list="dataTypes"
          :load-data="loadData"
          label
          @change="onCascaderChange"
          clearable
          placeholder="请选择"
        ></fm-cascader>
      </div>
      <div slot="footer" v-if="confirm" class="modal-footer-btns">
        <fm-btn @click="onChange">确定</fm-btn>
        <fm-btn @click="show = false">取消</fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import { getDataLabel, dataTypes } from '../lib'
export default {
  props: {
    task: { type: Object, default: null },
    confirm: { type: Boolean, default: true }
  },
  data () {
    return {
      dataTypes: JSON.parse(JSON.stringify(dataTypes)),
      dataId: null,
      dataType: null,
      show: false,
      data: null
    }
  },
  computed: {
    label () {
      return !this.data ? getDataLabel(this.task) : this.data.label
    }
  },
  watch: {
    show (v) {
      if (v && this.$refs.cascader) {
        this.$refs.cascader.toogle()
      }
    }
  },
  methods: {
    onChange () {
      this.$emit('change', 'dataId', this.dataId && this.dataType ? {dataId: this.dataId.value, dataType: this.dataType.value} : null)
      this.data = this.dataId
      if (this.dataId && this.dataType) {
        this.show = false
      }
    },
    onCascaderChange (v) {
      if (!v.length) {
        this.dataId = null
        this.dataType = null
        !this.confirm && this.onChange()
      } else if (v.length >= 2) {
        this.dataId = v[1]
        !this.confirm && this.onChange()
      } else {
        this.dataType = v[0]
      }
    },
    loadData (item) {
      let type = dataTypes.find(v => v.value === item.value)
      if (type && type.load) {
        return type.load.bind(this)()
      }
    }
  }
}
</script>
