<template>
  <data-item
    style="margin-left: -1rem;margin-right: 1rem;"
    v-if="typeItemConfig"
    :dataParm="{id: dataId}"
    :isDetail="true"
    :config="typeItemConfig">
  </data-item>
</template>

<script>
import { sys } from '@/data_conf/sys'
import { sysVersion } from '@/data_conf/sys_version'
import { app } from '@/data_conf/app'
import { appVersion } from '@/data_conf/app_version'
import { server } from '@/data_conf/server'
import { appService } from '@/data_conf/app_service'

const configMap = {
  'sys': sys({defaultOpen: true}),
  'sys_version': sysVersion({defaultOpen: true, getTitle: (data) => {
    return data ? (data.sysName + ' ' + data.versionNumber) : ''
  }}),
  'app': app({defaultOpen: true}),
  'app_version': appVersion({defaultOpen: true}),
  'server': server({defaultOpen: true}),
  'app_service': appService({defaultOpen: true})
}

import DataItem from '@/components/datainfo/DataItem'

export default {
  components: {
    DataItem
  },
  props: {
    dataId: {
      type: Number,
      default: null
    },
    dataType: {
      type: String,
      default: null
    }
  },
  computed: {
    typeItemConfig () {
      console.log('dataId', this.dataId, this.dataType)
      let data = null
      if (this.dataId && this.dataType && configMap[this.dataType]) {
        data = configMap[this.dataType]
      }
      return data
    }
  }
}
</script>