<style lang="less">
  .task-points {
    position: relative;
    &::before {
      content: '';
      background-color: #a2a2a2;
      width: 1px;
      top: 5px;
      bottom: 0;
      position: absolute;
    }
    padding-right: 10px;
    margin-left: 20px;
    & > * {
      margin-top: 15px;
    }
  }
</style>

<template>
  <div class="task-points">
    <template v-for="(item, i) in dataList">
      <component :is="item.type === 'history' ? historyCmp : replayCmp" :key="i + item.type + item.data.id" :data="item.data"></component>
    </template>
  </div>
</template>

<script>
import { workOperationReuqest, taskReplyRequest } from '@/api'
import PointsHistory from './history'
import PointsReplay from './replay'
export default {
  props: {
    id: { type: Number, default: null }
  },
  data () {
    return {
      dataList: []
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  computed: {
    historyCmp () {
      return PointsHistory
    },
    replayCmp () {
      return PointsReplay
    }
  },
  methods: {
    async loadData () {
      if (this.id) {
        let logs = workOperationReuqest.getLog({
          historyTableName: 'history_task',
          historyDataId: this.id
        })
        let replays = taskReplyRequest.get({
          taskId: this.id
        })

        logs = await logs
        replays = await replays
        
        let data = [
          ...logs.map(v => {
            return {
              type: 'history',
              tim: v.createTime,
              data: v
            }
          }),
          ...replays.map(v => {
            return {
              type: 'replay',
              tim: v.createTime,
              data: v
            }
          })
        ]
        data.sort((a, b) => {
          return new Date(b.tim) - new Date(a.tim)
        })
        this.dataList = data
      } else {
        this.dataList = []
      }
    }
  },
  async mounted () {
    this.loadData()
  }
}
</script>
